.main{
	background: #fff;
	& .banner{
		background: url(/assets/img/home/banner.png) no-repeat top;
		height: 605px;
		color: #fff;
		text-align: center;
		background-size: cover;
		min-width: 1200px;
    position: relative;
    & .canvas-use-img {
      display: none;
    }
    & #pointAnimate {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 100%;
      width: 100%;
      transform: translate(-50%, -50%);
      z-index: 2;
    }
		& div.h1{
			font-size: 72px;
			line-height: 72px;
			padding-top: 220px;
			padding-bottom: 30px;
			color: #fff;
      text-align: center;
		}
		& p{
			font-size: 42px;
			line-height: 42px;
      text-align: center;
		}
	}
	& .cloud-platform{
		text-align: center;
		width: 1200px;
		margin: 0 auto;
		padding: 82px 0 62px;
		& .cloud-platform-title{
			line-height: 34px;
			margin-bottom:90px;
		}
		/*& .cloud-platform-produce{
			font-size: 16px;
			line-height: 16px;
			color: #666;
			margin-bottom: 90px;
		}*/
		& .platform{
			& .content{
				float: left;
				& img{
					    margin-bottom: 20px;
				}
				& div.h2{
          line-height: 52px;
          font-size: 24px;
				}
				& p{
					font-size: 14px;
					line-height: 25px;
					color: #666;
				}
			}
			& .technology{
				width: 240px;
			}
			& .process{
				width: 705px;
			}
			& .develop{
				width: 255px;
			}
		}
	}
	& .six-service-bg{
		background: #f6f6f6;
		padding: 62px 0 59px;
		min-width: 1200px;
		& .six-service{
			width: 1200px;
			margin: 0 auto;
			text-align: center;
			& div.h1{
				line-height: 34px;
				& em{
					font-style: italic;
					font-weight: bold;
				}
			}
			& .six-service-produce{
				font-size: 16px;
				color: #666;
				line-height: 16px;
				padding: 18px 0 50px;
			}
			& .service-top,& .service-bottom{
				& .content{
					float: left;
					background: #fff;
					width: 368px;
					height: 365px;
					font-size: 0;
					cursor: pointer;
          border: 1px solid #f6f6f6;
          transition: all 0.4s;
					& img{
						margin: 55px 0 35px;
					}
					& div.h2{
						font-size: 20px;
						line-height: 20px;
						margin-bottom: 15px;
					}
					& p{
						color: #666;
						font-size: 14px;
						line-height: 14px;
					}
				}
				& .content:hover{
/*					width: 368px;
					height: 353px;*/
					border: 1px solid #2594e9;
          transform: scale(1.06);
          box-shadow: 0 0 5px 5px rgba(23, 48, 88, 0.1);
				}

				& .center{
					margin: 0 45px;
				}
			}
			& .service-top{
					margin-bottom: 45px;
			}
		}

	}

}

.nav-header{
	& .links{
		& a.index{
			border-bottom: 2px solid #15a4ff;
		}
	}
}




















